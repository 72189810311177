import React, { FC, Fragment } from 'react';

import { ComponentProps as ButtonProps } from '../../Button/Button.types';
import PlxsButton from '../../Button';

import { ComponentProps } from './types';

const TriggerButton: FC<ComponentProps> = ({
  button,
  dataTestId,
  isActive,
  ...commonButtonProps
}) => (
  <Fragment>
    {React.isValidElement<ButtonProps>(button) ? (
      React.cloneElement<ButtonProps>(button, {
        isActive,
        functionalIcon: isActive ? 'chevronUp' : 'chevronDown',
        ...commonButtonProps,
      })
    ) : (
      <PlxsButton
        dataTestId={`${dataTestId}-button`}
        functionalIcon="options"
        isActive={isActive}
        isIconOnly
        label="Kebab menu"
        size="sm"
        tone="neutral"
        variant="secondary"
        {...commonButtonProps}
      />
    )}
  </Fragment>
);

export default TriggerButton;
