import React, { FC, useState } from 'react';

import { ComponentProps } from './ActionMenu.types';
import { useStyles } from './ActionMenu.styles';
import MenuItem from '../_internal/MenuItem';
import Popover from '../_internal/Popover';
import MenuList from '../_internal/MenuList';
import { POPOVER_RIGHT_START } from '../_internal/Popover/constants';
import TriggerButton from './TriggerButton';

const ActionMenu: FC<ComponentProps> = ({
  dataTestId,
  id,
  isCloseOnSelect = true,
  isDisabled = false,
  isUnmountOnTransitionedOut = true,
  getOptionState,
  onSelect,
  options,
  placement = POPOVER_RIGHT_START,
  renderOption,
  triggerButton,
  isFullWidth,
}) => {
  const classes = useStyles();

  const [anchorElement, setAnchorElement] =
    React.useState<HTMLButtonElement | null>(null);

  const [isMenuFocus, setIsMenuFocus] = useState(false);

  const isMenuOpen = Boolean(anchorElement);

  const handleAnchorElementClick = (event) => {
    const target = event.currentTarget;
    setAnchorElement(anchorElement ? null : target);
  };

  const handleClickAway = () => setAnchorElement(null);

  const handleItemSelect = (index: number) => {
    onSelect(options[index]);
    if (isCloseOnSelect) {
      setAnchorElement(null);
    }
  };

  const handlePopoverTransitionedOut = () => setIsMenuFocus(false);

  const handlePopoverCreated = () => setIsMenuFocus(true);

  return (
    <div className={isFullWidth ? classes.blockRoot : classes.inlineRoot}>
      <TriggerButton
        dataTestId={dataTestId}
        id={id}
        isActive={isMenuOpen}
        isDisabled={isDisabled}
        onClick={handleAnchorElementClick}
        button={triggerButton}
      />
      <Popover
        anchorElement={anchorElement}
        dataTestId={`${dataTestId}-popover`}
        isOpen={isMenuOpen}
        onClickAway={handleClickAway}
        onCreated={handlePopoverCreated}
        onTransitionedOut={handlePopoverTransitionedOut}
        placement={placement}
        transitionModifiers={{ unmountOnExit: isUnmountOnTransitionedOut }}
      >
        <div className={classes.menuListWrapper}>
          <MenuList
            autoFocus={isMenuFocus}
            onKeyExit={handleClickAway}
            onSelect={handleItemSelect}
          >
            {options.map((option, index) => {
              const menuItemProps = getOptionState
                ? getOptionState(option)
                : {};

              return (
                <MenuItem
                  {...menuItemProps}
                  hasBorderRadius
                  key={index}
                  value={index}
                >
                  {renderOption(option)}
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      </Popover>
    </div>
  );
};

export default ActionMenu;
